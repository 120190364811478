import React, { useMemo } from 'react';
import { Button } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { useGetPrivaciesDocs } from '@/controllers/landing/landing.helpers';
import styles from './FooterTermsLinks.module.scss';

export const FooterTermsLinks = () => {
  const { t } = useTranslation(I18N_CODES.common);

  const { termsOfUse, privacyPolicy, cookiesPolicy } = useGetPrivaciesDocs();
  const linkItems = useMemo(() => (
    [
      {
        id: 1, title: `${I18N_CODES.common}:terms_of_use`, link: termsOfUse,
      },
      {
        id: 2, title: `${I18N_CODES.common}:privacy_policy`, link: privacyPolicy,
      },
      {
        id: 3, title: `${I18N_CODES.common}:footer_cookies_policy`, link: cookiesPolicy,
      },
    ]
  ), [termsOfUse, privacyPolicy, cookiesPolicy]);

  return (
    <div>
      {linkItems.map((item) => (
        <Button
          key={item.id}
          href={item.link}
          target="_blank"
          rel="nofollow noreferrer noopener"
          text={t(item.title)}
          size={Button.size.Large}
          mode={Button.mode.TransparentDark}
          className={styles.linkButton}
          isInternalLink={false}
        />
      ))}
    </div>
  );
};
