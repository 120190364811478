import { useEffect, useState } from 'react';
import { LogLevels } from '@mate-academy/logger';
import { useStateRef } from '@/hooks/useStateRef';
import { errorHandler } from '@/core/ErrorHandler';
import { logger } from '@/core/Logger';

interface ObserverInterface {
  initialState?: boolean;
  toggleOnError?: boolean;
  rootMargin?: string;
  noUnobserve?: boolean;
  root?: HTMLElement | null;
}

// observer

export const useIntersectionObserver = (options: ObserverInterface) => {
  const {
    initialState = false,
    toggleOnError = false,
    rootMargin = '0px 0px 200px 0px',
    noUnobserve = false,
    root = null,
  } = options;

  const [inView, setInView] = useState(initialState);
  const [element, setElementRef] = useStateRef<HTMLDivElement>();

  useEffect(() => {
    try {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const { isIntersecting } = entry;

            if (noUnobserve) {
              setInView(isIntersecting);
            } else if (isIntersecting) {
              setInView(true);
              observer.disconnect();
            }
          });
        },
        {
          root,
          rootMargin,
        },
      );

      if (element) {
        observer.observe(element);
      }

      return () => {
        observer.disconnect();
      };
    } catch (error) {
      if (toggleOnError) {
        setInView(!initialState);
      }

      errorHandler.captureException(error, {
        logLevel: LogLevels.Error,
        logMessage: 'Failed to create IntersectionObserver',
        logger: logger.child('useIntersectionObserver'),
      });
    }

    return () => { /* do nothing */ };
  }, [element, noUnobserve, root, rootMargin, toggleOnError, initialState]);

  return {
    inView,
    setInView,
    element,
    setElementRef,
  };
};
