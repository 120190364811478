import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconVisa: FCIcon = (props) => (
  <BaseIcon
    width="38"
    height="23"
    viewBox="0 0 38 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_10098_51670)">
      <path d="M38 23H0V0H38V23Z" fill="white" />
      <path d="M0 19.5785H38V23H0V19.5785Z" fill="#F7B600" />
      <path d="M0 3.42188H38V0.00038743H0V3.42188Z" fill="#1A1F71" />
      <path d="M18.6687 6.98713L16.6726 16.0433H14.2583L16.2546 6.98713H18.6687ZM28.826 12.835L30.097 9.43392L30.8281 12.835H28.826ZM31.5209 16.0433H33.7534L31.803 6.98713H29.7436C29.2794 6.98713 28.8884 7.24832 28.7152 7.65101L25.0924 16.0433H27.6279L28.1314 14.6909H31.2284L31.5209 16.0433ZM25.2181 13.0867C25.2288 10.6967 21.8136 10.5642 21.8363 9.4964C21.8438 9.17185 22.1626 8.82618 22.86 8.73783C23.2058 8.69453 24.1596 8.65968 25.2411 9.14316L25.6641 7.22192C25.0831 7.01811 24.3357 6.82169 23.4059 6.82169C21.0191 6.82169 19.34 8.05194 19.3266 9.81514C19.3113 11.119 20.5259 11.8459 21.4391 12.2801C22.3808 12.7239 22.6964 13.0087 22.6918 13.4052C22.6853 14.0128 21.9408 14.2817 21.248 14.2919C20.0332 14.3102 19.3295 13.9734 18.7683 13.7196L18.3301 15.7049C18.8951 15.9559 19.9361 16.1743 21.0142 16.1855C23.5515 16.1855 25.2106 14.9695 25.2181 13.0867ZM15.219 6.98713L11.3071 16.0433H8.75522L6.83001 8.81579C6.71339 8.37139 6.61165 8.20806 6.25671 8.02026C5.67616 7.7142 4.71763 7.42802 3.875 7.2499L3.93195 6.98713H8.04027C8.56352 6.98713 9.03435 7.32505 9.15405 7.90991L10.171 13.1509L12.6825 6.98713H15.219Z" fill="#1A1F71" />
    </g>
    <defs>
      <clipPath id="clip0_10098_51670">
        <rect width="38" height="23" fill="white" />
      </clipPath>
    </defs>
  </BaseIcon>
);
