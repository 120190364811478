import { useEffect } from 'react';
import { shouldShowGBConsoleMessages } from '@/components/services/GrowthBook/GrowthBook.helpers/GrowthBook.helpers';

interface Props {
  featureName: string;
  variant: number | string;
  ready: boolean;
  isForceSelectedFromQuery?: boolean;
}

export const useExperimentLogs = (props: Props) => {
  const {
    featureName,
    variant,
    ready,
    isForceSelectedFromQuery = false,
  } = props;

  const shouldShowGBMessages = shouldShowGBConsoleMessages();

  useEffect(() => {
    if (shouldShowGBMessages) {
      if (ready) {
        if (isForceSelectedFromQuery) {
          // eslint-disable-next-line no-console
          console.log(`🚨🚨 Experiment force variant ready 🚨🚨`, {
            featureName,
            variant,
          });
        } else {
          // eslint-disable-next-line no-console
          console.log(`✅✅ Experiment ready ✅✅`, {
            featureName,
            variant,
          });
        }
      } else {
        // eslint-disable-next-line no-console
        console.log(`⏳⏳ Experiment loading ⏳⏳`);
      }
    }
  }, [
    shouldShowGBMessages,
    isForceSelectedFromQuery,
    featureName,
    variant,
    ready,
  ]);
};
