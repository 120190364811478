import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useTranslateByDomain } from '@/hooks/useTranslateByDomain';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';

export const useGetPrivaciesDocs = () => {
  const { isKnowelyProduct } = useSubDomainContext();
  const { t } = useTranslation([I18N_CODES.common]);

  const defaultDocs = {
    termsOfUse: useTranslateByDomain(`${I18N_CODES.common}:terms-of-use`),
    privacyPolicy: useTranslateByDomain(`${I18N_CODES.common}:privacy-policy`),
    cookiesPolicy: useTranslateByDomain(`${I18N_CODES.common}:cookies-policy`),
  };

  if (isKnowelyProduct) {
    return ({
      termsOfUse: t(`${I18N_CODES.common}:terms-of-use|knowely`),
      privacyPolicy: t(`${I18N_CODES.common}:privacy-policy|knowely`),
      cookiesPolicy: t(`${I18N_CODES.common}:cookies-policy|knowely`),
    });
  }

  return defaultDocs;
};
