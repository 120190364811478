import React, { memo } from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { IconVisa } from '@/components/ui/icons/IconVisa';
import { IconMasterCardWithText } from '@/components/ui/icons/IconMasterCardWithText';
import { FooterTermsLinks } from '@/components/landing/FooterTermsLinks/FooterTermsLinks';
import { useTranslateByDomain } from '@/hooks/useTranslateByDomain';
import styles from './LandingFooterWebsiteInfo.module.scss';

export const LandingFooterWebsiteInfo = memo(() => {
  const companyName = useTranslateByDomain(`${I18N_CODES.common}:ma_copyright`);

  return (
    <div className={styles.infoWrapper}>
      <div data-qa="footer-website-info" className={styles.copyright}>
        <p className={cn(typography.landingH6, typography.paragraph, 'mr-24')}>
          {companyName}
        </p>

        <IconVisa className={cn(styles.iconVisa, 'mr-8')} />
        <IconMasterCardWithText className={cn(styles.iconMasterCard, 'color-white')} />
      </div>

      <div data-qa="footer-terms-links" className={cn(styles.termsLinks, 'medium-mb-48 small-mb-40')}>
        <FooterTermsLinks />
      </div>
    </div>
  );
});
