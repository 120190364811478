export const GBExperiments = {
  // SUBSCRIPTION
  subscriptionProductSignUpFlow: {
    feature: 'subscription-product-sign-up-flow',
  },
  subscriptionProductCourseContentLP: {
    feature: 'course-content-lp',
  },
  subscriptionProductSkillBasedLp: {
    feature: 'skill-based-lp',
  },
  ftSurveySignUp: {
    feature: 'ft-survey-sign-up',
  },

  // ACQUISITION
  webflowQuizVersionsSplit: {
    feature: 'webflow-quiz-versions-split',
  },
  helpcrunchIntegration: {
    feature: 'helpcrunch-chat-on-landings',
  },
  ongoingTypeformExperiment: {
    feature: 'ongoing-typeform-experiment',
  },
  redesignCoursePagePL: {
    feature: 'redesign-course-page-pl',
  },
  exitPopup40PercentScroll: {
    feature: 'exit-popup-40-percent-scroll',
  },
  redesignCoursesParttimePageUa: {
    feature: 'redesign-courses-parttime-page-ua',
  },
};

export const GBFeatures = {
  monetaryBonusSection: {
    name: 'monetary-bonus-section',
    defaultValue: false,
  },
  shouldRedirectFromSurveyToConsultation: {
    name: 'should-redirect-from-survey-to-consultation',
    defaultValue: false,
  },
};

export enum GBExperimentVariants {
  Disabled = -1,
  Control = 0,
  Variant1 = 1,
  Variant2 = 2,
  Variant3 = 3,
}

export const GBExperimentDefaultStringValue = 'false';
