import { useNumberFormatter } from '@/controllers/i18n/i18n.hooks/useNumberFormatter';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';

export const useEmployedStudentsCount = (): string => {
  const formatter = useNumberFormatter();

  const { t } = useTranslation(I18N_CODES.common);

  const employedCount = Number(
    t(`${I18N_CODES.common}:employed_students_count`),
  );

  return formatter.customFormat(employedCount);
};
