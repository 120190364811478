import { useEffect } from 'react';
import { BG_BACKFILL_CLASS, THEME_ATTRIBUTE } from '@/components/common/ThemeContext/constants';

export const LightThemeLayoutDefender = () => {
  useEffect(() => {
    document.documentElement.removeAttribute(THEME_ATTRIBUTE);
    document.documentElement.classList.remove(BG_BACKFILL_CLASS);
  }, []);

  return null;
};
