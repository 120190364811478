import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { LandingSocialLinks } from '@/components/landing/LandingSocialLinks';
import styles from './LandingFooterDomainActions.module.scss';

export const LandingFooterDomainActions: FC = memo(() => (
  <div className={styles.footerDomainActions}>
    <div data-qa="footer-social-wrapper" className={cn(styles.socialWrapper)}>
      <LandingSocialLinks
        buttonMode={Button.mode.TransparentDark}
      />
    </div>
  </div>
));
