import React, { FC } from 'react';
import { Seos } from '@/components/common/Seos';
import { LayoutProps } from '@/components/layout/LayoutProps';
import { LightThemeLayoutDefender } from '@/components/layout/LightThemeLayoutDefender';

type Props = LayoutProps;

export const EmptyLandingLayout: FC<Props> = ({
  children,
  layoutOptions,
}) => (
  <>
    <Seos seo={layoutOptions?.seo} />
    <LightThemeLayoutDefender />

    {children}
  </>
);
