import React from 'react';
import dynamic from 'next/dynamic';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';
import { useGrowthBookExperiment } from '@/components/services/GrowthBook/GrowthBook.hooks/useGrowthBookExperiment';
import { GBExperiments } from '@/components/services/GrowthBook/GrowthBook.constants';

const SupportSocialMediasCollapsable = dynamic(
  () => import('@/components/common/SupportSociaMedias')
    .then((mod) => mod.SupportSocialMediasCollapsable),
);

const HelpCrunchScript = dynamic(
  () => import('@/components/services/HelpCrunch/HelpCrunchScript')
    .then((mod) => mod.HelpCrunchScript),
);

export const LandingSupportButton = () => {
  const features = useFeatures();
  const { subDomain } = useSubDomainContext();

  const shouldShowHelpCrunch = (
    features.isEnabled(features.HelpCrunchIntegration)
    && subDomain === SubDomains.pl
  );

  const {
    isVariant1: isHelpCrunchActive,
    ready: isExperimentReady,
  } = useGrowthBookExperiment(
    GBExperiments.helpcrunchIntegration.feature,
  );

  const isReadyToRender = isExperimentReady || !shouldShowHelpCrunch;

  if (!isReadyToRender) {
    return null;
  }

  if (isHelpCrunchActive && shouldShowHelpCrunch) {
    return <HelpCrunchScript />;
  }

  return <SupportSocialMediasCollapsable shouldShowScrollToTop={false} />;
};
